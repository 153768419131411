import commonAct from '@/commonActionHandle.js';
import { mapState } from "vuex";
import baseComponent from "@/scripts/baseComponent";
import vmMachineApi from '@/api/gpuHub/vmMachines';
import { debounce } from 'vue-debounce';
import lazyLoadComponent from '@/scripts/lazyLoadComponent';
import SkeletonBox from '@/components/SkeletonBox';
import SkeletonBoxWithoutLoading from "@/components/SkeletonBoxWithoutLoading";
import { colorGpuNodeColor, vmMachineStatusText, vmMachineStatus, vmMachineStatusColor }
    from "@/constant/vmMachineStatusConst";
import { getlazyLoadInfo } from "@/scripts/_helpers/userHelper";
export default {
    extends: baseComponent,
    props: {
        statusFiltered: {
            type: Number,
            default: 0
        },
        cardHeight: {
            type: String,
            default: 'height: calc(100dvh - 7rem);'
        },
        minInfo: {
            type: Boolean,
            default: false
        },
        ownedTarget: {
            type: String,
            default: null
        },
    },
    components: {
        detailMachine: lazyLoadComponent({
            componentFactory: () => import("@/views/gpuHub/vmMachines/detailVmMachine"),
            loading: SkeletonBox,
        }),
        detailAbandonedNotify: lazyLoadComponent({
            componentFactory: () => import("@/views/gpuHub/vmMachines/detailNotifyMachine"),
            loading: SkeletonBox,
        }),
        ColumnBalance: lazyLoadComponent({
          componentFactory: () => import("@/views/components/column/Balance.vue"),
          loading: SkeletonBoxWithoutLoading,
        }),
        renderSoftwareFocus: lazyLoadComponent({
            componentFactory: () => import("@/views/users/RenderSoftwareFocus"),
            loading: SkeletonBoxWithoutLoading,
        }),
    },
    data() {
        return {
            VUE_APP_API_HOST: process.env.VUE_APP_API_HOST,
            debounceFnc: null,
            mindate:'0001-01-01T00:00:00',
            debounceFilterFnc: null,
            timeForCreatingImage: 3000,
            isFilterEnable:false,
            numberOfDay: 30,
            intervalUpdate: null,
            colorGpuNodeColor: colorGpuNodeColor,
            vmMachineStatus: vmMachineStatus,
            vmMachineStatusColor: vmMachineStatusColor,
            elements: {
                queryAction: "",
                querySearch: "",
                filterItem: {},
                staff: "",
                notifyStatus:0, 
                userLevel: 0, 
                partnerId: -1,
                data: [],
                pagingItem: {
                    pageIndex: 1,
                    pageSize: 20,
                    numberOfPage: 1,
                    outRowsNumber: 0,
                    orderBy: "A.LAST_MODIFIED_AT",
                    directionSort: "desc"
                }
            },
            rentalPackage: [],
            toogleFilter: false,
            elementDetail: null,
            serverStatus: [],
            summaryInfo: {
                availableGPU: 0,
                availableCPU: 0,
                canBoot: false,
                canBootCpu: false,
                totalGPU: 0,
                totalCPU: 0,
                runningGPU: 0,
                runningCPU: 0,
            },
            warningMail: {
                warningMailList: [],
                fullItemList:[],
                pageNumber: 1,
                numItemsPerPage: 10,
            },
            notifyViewMode: 0,
            isCheckAll: false,
            itemsSelected: [],
        };
    },
    mounted() {
        
    },
    beforeDestroy() {
        //this.sockets.unsubscribe("irender.rental.update");
    },
    computed: {
        ...mapState({
          userInfo: (state) => state.auth.user,
          rolesInfo: (state) => state.auth.roles,
          staffsInfo: (state) => state.staffs,
          decentralization: (state) => state.auth.decentralization,
        }),
        defaultPagingItem() {
            return {
                pageIndex: 1,
                pageSize: 20,
                numberOfPage: 1,
                outRowsNumber: 0,
                orderBy: "A.LAST_MODIFIED_AT",
                directionSort: "desc"
            };
        },
        requestParam() {
            return {
                ownedTarget: this.ownedTarget,
                queryAction: this.elements.queryAction,
                querySearch: this.elements.querySearch,
                pageSize: this.elements.pagingItem.pageSize,
                orderBy: this.elements.pagingItem.orderBy,
                directionSort: this.elements.pagingItem.directionSort,
                filterItem: this.elements.filterItem,
                notifyStatus: this.statusFiltered,
                userLevel: this.elements.userLevel,
                staff: this.elements.staff,
                partnerId: this.elements.partnerId,
            };
        },
        machineStatusFilter() {
            return Object.keys(vmMachineStatusText).map(x => {
                return { id: x, text: vmMachineStatusText[x] };
            });
        },
        machinePackageFilter() {
            return this.rentalPackage.map(x => {
                return { id: x.id, text: x.name };
            });
        },
        cpuNodesAvailability() {
            if (this.summaryInfo.totalCPU > 0) {
                let availableCPU = (this.summaryInfo.availableCPU / this.summaryInfo.totalCPU) * 100;
                if (availableCPU < 30) return { status: "Low", classes: 'danger', percent: availableCPU, striped: false };
                if (availableCPU < 70) return { status: "Medium", classes: 'info', percent: availableCPU, striped: false };
                else return { status: "High", classes: 'success', percent: availableCPU, striped: false };
            }
            return { status: "Cheking..", classes: 'muted', percent: 0, striped: true, };
        },
        gpuNodesAvailability() {
            if (this.summaryInfo.totalGPU > 0) {
                let availableGPU = (this.summaryInfo.availableGPU / this.summaryInfo.totalGPU) * 100;
                if (availableGPU < 30) return { status: "Low", classes: 'danger', percent: availableGPU, striped: false };
                if (availableGPU < 70) return { status: "Medium", classes: 'info', percent: availableGPU, striped: false };
                else return { status: "High", classes: 'success', percent: availableGPU, striped: false, };
            }
            return {
                status: "Cheking..",
                classes: 'muted',
                percent: 0,
                striped: true,
            };
        }
    },
    created() {
        this.showLoading();
        this.loadRentalPackage();
        this.loadRentalServerStatus();
        this.getAllResult(1);
        this.debounceFnc = debounce(() => {
            this.getSearchQueryResult(1);
        }, 1000);
        this.debounceFilterFnc = debounce(() => {
            this.getFilterQueryResult(1);
        }, 1000);
    },
    methods: {
        loadRentalPackage() {
            vmMachineApi.getPackageList().then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.rentalPackage = response.data.data.data;
                    //this.hideLoading();
                    this.calculateInterval();
                }
                else {
                    this.rentalPackage = [];
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
                this.rentalPackage = [];
            });
        },
        loadRentalServerStatus() {
            vmMachineApi.getAllServerReport().then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.serverStatus = response.data.data.data.map(item => {
                        return {
                            "package": item.package,
                            "total": item.total,
                            "available": item.available,
                            "status_text": item.statusText
                        };
                    });
                    //this.hideLoading();
                }
                else {
                    this.serverStatus = [];
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
                this.serverStatus = [];
            });
        },
        getElementsList(pageNumber) {
            this.showLoading();
            vmMachineApi.getListMachineAbandoned(this.numberOfDay, pageNumber, this.requestParam).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.elements.data = response.data.data.data;
                    this.elements.pagingItem = response.data.data.pagingItem;
                    this.$nextTick(() => {
                        this.hideLoading();
                    });
                }
                else {
                    this.elements.data = [];
                    this.elements.pagingItem = this.defaultPagingItem;
                    this.hideLoading();
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        commonAct.showError(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
                this.elements.data = [];
                this.elements.pagingItem = this.defaultPagingItem;
                this.hideLoading();
                commonAct.showError(error);

            });
        },
        getAllResult(pageNumber) {
            this.elements.queryAction = "";
            this.elements.filterItem = {};
            this.getElementsList(pageNumber);
        },
        getSearchQueryResult(pageNumber) {
            this.elements.queryAction = "search";
            this.elements.filterItem = {};
            this.getElementsList(pageNumber);
        },
        getFilterQueryResult(pageNumber) {
            this.elements.queryAction = "filter";
            this.getElementsList(pageNumber);
        },
        pageClickHandle(pageNumber) {
            switch (this.elements.queryAction) {
                case "filter":
                    this.getFilterQueryResult(pageNumber);
                    break;
                case "search":
                    this.getSearchQueryResult(pageNumber);
                    break;
                default:
                    this.getAllResult(pageNumber);
                    break;
            }
        },
        refreshClick() {
            this.elements.querySearch = "";
            this.getAllResult(1);
        },
        onSearchInput(isDebounce) {
            if (isDebounce) {
                if (this.debounceFnc) this.debounceFnc();
            } else {
                this.getSearchQueryResult(1);
            }
        },
        onFilterInput(isDebounce) {
            if (isDebounce) {
                if (this.debounceFilterFnc) this.debounceFilterFnc();
            } else {
                this.getFilterQueryResult(1);
            }
        },
        FilterNotified(value) {
                 this.onFilterInput(value);
        },
        filterPackage(item) {
            this.elements.filterItem.statusFilter = [vmMachineStatus.Running, vmMachineStatus.Starting, vmMachineStatus.Restaring].join(',');
            this.elements.filterItem.packageName = this.$store.state.gpuNodeName[item.package].id;
            this.onFilterInput(false);
        },
        viewDetailInfo(targetItem) {
            this.$set(this, "elementDetail", targetItem);
            console.log("elementDetail", this.elementDetail);
            this.$nextTick(() => {
                $("#detailModalInfo").modal({ backdrop: 'static', keyboard: true, });
            });
        },
        closeDetailInfo() {
            $("#detailModalInfo").modal("hide");
            this.$set(this, "elementDetail", null);
            console.log("elementDetail", this.elementDetail);
        },
        // Calculate interval
        calculateInterval() {
            this.intervalUpdate = setInterval(() => {
                this.elements.data.forEach(element => {
                    if (element.vmMachineInfo.status === this.vmMachineStatus.Running) {
                        element.vmMachineInfo.uptime = parseInt(element.vmMachineInfo.uptime);
                        element.vmMachineInfo.rentalServicePack.pricing = parseFloat(element.vmMachineInfo.rentalServicePack.pricing);
                        element.vmMachineInfo.uptime += 1;
                        let places = 4;
                        element.estimatedTotal = Math.round(((element.vmMachineInfo.uptime * element.vmMachineInfo.rentalServicePack.pricing) / (60 * 60) + 0.00001) * 1000) / 1000;
                    }
                    //var startTimeDuration = moment.utc() - moment.utc((element.vmMachineInfo.loadingTime !== null ? element.vmMachineInfo.loadingTime : element.vmMachineInfo.createdAt));
                    //var percentCreating = (moment.duration(startTimeDuration).asSeconds() / this.timeForCreatingImage);
                    //if (this.IsShowLoading) {
                    //    if (percentCreating < 0.99) {
                    //        element.percentLoad = percentCreating >= 0 ? percentCreating : 0;
                    //    }
                    //    else {
                    //        element.percentLoad = 0.99;
                    //    }
                    //}
                });
            }, 1000);
        },
        proccessingEventMachine(message) {
            switch (message.cmdName) {
                case "UpdateServerStatus":
                    //console.log("UpdateServerStatus", message.data);
                    if (message.data !== null) this.$set(this, "serverStatus", message.data);
                    break;
                case "UpdateSummaryInfo":
                    //console.log("UpdateSummaryInfo", message.data);
                    if (message.data !== null) this.$set(this, "summaryInfo", message.data);
                    break;
                case "UpdateRdpStatus":
                    //console.log("UpdateRdpStatus", message.data.data);
                    var messageUpdateRdpStatus = message.data.data;
                    if (messageUpdateRdpStatus !== null) {
                        let itemTargetRdpUpdated = this.elements.data.find(element => element.id === messageUpdateRdpStatus.vmMachineId);
                        if (itemTargetRdpUpdated !== null) {
                            this.$set(itemTargetRdpUpdated, "canRemoteRdpReady", messageUpdateRdpStatus.remoteReady);
                        }
                    }
                    break;
            }
        },
        exportData() {
            let host = process.env.VUE_APP_API_HOST.slice(0, -1);
            window.open(host + vmMachineApi.exportListMachineAbandoned(this.numberOfDay, 1, this.requestParam), '_blank');
        },
        removeImage(targetItem) {
            commonAct.showConfirm(`Remove image <strong>${targetItem.vmMachineInfo.name}</strong> ? This cannot be undone`, () => {
                this.showLoading();
                let request = {
                    machineId: targetItem.vmMachineId,
                    deviceInformation: JSON.stringify(this.getInformationClient()),
                };
                vmMachineApi.removeMachine(request).then(response => {
                    if (response.data && response.data.result === 0) {
                        this.hideLoading();
                        this.showSuccessToast("Remove image has been success");
                        let foundedItemIndex = this.elements.data.findIndex(x => x.vmMachineId === targetItem.vmMachineId);
                        if (foundedItemIndex > -1) {
                            this.elements.data.splice(foundedItemIndex, 1);
                            this.getSearchQueryResult(this.elements.pagingItem.pageIndex);
                        }
                    }
                    else {
                        this.hideLoading();
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            commonAct.showError(response.data.message || this.$lang.common.error);
                        }
                    }
                }).catch(error => {
                    console.error(error);
                    this.hideLoading();
                    commonAct.showError(error);

                });
            });
        },
        //Notification
        addToNotifyList(targetItem) {
            console.log(targetItem);
            try{
                var index = this.warningMail.warningMailList.findIndex(x => x.id === targetItem.id);
                if(index<0){
                    this.warningMail.warningMailList.push(targetItem);
                }
            }catch(err){
                console.log("addToNotifyList",err);
            }
        },
        removeFromNotifyList(targetItem) {
            try{
                var index = this.warningMail.warningMailList.findIndex(x => x.id === targetItem.id);
                if(index>-1){
                    console.log(index);
                    this.warningMail.warningMailList.splice(index,1);
                }
            }catch(err){
                console.log("addToNotifyList",err);
            }
            console.log(this.warningMail.warningMailList);
        },
        confirmToKeep(targetItem) {
                commonAct.showConfirm(`Keep this image?`, () => {
                    this.showLoading();
                let request = targetItem;
                vmMachineApi.confirmToKeep(request).then(response => {
                    if (response.data && response.data.result === 0) {
                        this.hideLoading();
                        this.showSuccessToast("Update to keep image succedd!");
                        let foundedItemIndex = this.elements.data.findIndex(x => x.vmMachineId === targetItem.vmMachineId);
                        if (foundedItemIndex > -1) {
                            this.elements.data[foundedItemIndex]= response.data.data;
                        }
                    }
                    else {
                        this.hideLoading();
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            commonAct.showError(response.data.message || this.$lang.common.error);
                        }
                    }
                }).catch(error => {
                    console.error(error);
                    this.hideLoading();
                    commonAct.showError(error);
                });
            });
        },
        ViewNotifyList() {
            try{
                this.notifyViewMode = 1;
                this.elements.data = this.warningMail.warningMailList;
            }catch(err){
                console.log("ViewNotifyList",err);
            }
        },
        ViewNormalList() {
            try{
                this.notifyViewMode = 0;
                this.getAllResult(this.elements.pagingItem.pageIndex);
            }catch(err){
                console.log("ViewNormalList",err);
            }
        },
        EnableFilter() {
            this.isFilterEnable = !this.isFilterEnable
        },
        
        NotifyWarning() {
            try{
                if(this.warningMail.warningMailList.length>0){
                    commonAct.showConfirm(`Send warning email to users? </br> This cannot be undone`, () => {
                        this.showLoading();
                        
                        vmMachineApi.notifySelectedMachines(this.warningMail.warningMailList).then(response => {
                            if (response.data && response.data.result === 0) {
                                this.warningMail.warningMailList=[];
                                this.getAllResult(this.elements.pagingItem.pageIndex);
                                this.hideLoading();
                                this.showSuccessToast("Notify succeed");
                            }
                            else {
                                this.hideLoading();
                                if (response.data && response.data.message !== null && response.data.message !== '') {
                                    commonAct.showError(response.data.message || this.$lang.common.error);
                                }
                            }
                        }).catch(error => {
                            console.error(error);
                            this.hideLoading();
                            commonAct.showError(error);
        
                        });
                    }); 
                }
            }catch(err){ 
                console.log("addToNotifyList",err);
            }
        },
        checkAllItem() {
            this.warningMail.warningMailList = [];
            if (!this.isCheckAll) {
                this.itemsSelected = [];
            }
            else {
                this.itemsSelected = this.elements.data.map(x => x.vmMachineId);
            }
        },        
        removeSelectedMachine() {
            try{
                if(this.itemsSelected.length>0){
                    commonAct.showConfirm(`Remove selected image ? </br> This cannot be undone`, () => {
                        this.showLoading();                        
                        vmMachineApi.removeSelectedMachine(this.itemsSelected).then(response => {
                            if (response.data && response.data.result === 0) {
                                this.itemsSelected = [];
                                this.isCheckAll = false;
                                this.getAllResult(1);
                                this.showSuccessToast(`Machine selected has been removed.`);
                            }
                            else {
                                this.hideLoading();
                                if (response.data && response.data.message !== null && response.data.message !== '') {
                                    commonAct.showError(response.data.message || this.$lang.common.error);
                                }
                            }
                        }).catch(error => {
                            console.error(error);
                            this.hideLoading();
                            commonAct.showError(error);
        
                        });
                    }); 
                }
            }catch(err){
                console.log("addToNotifyList",err);
            }
        },
        onWaypoint({ element }) {
            // going: in, out
            // direction: top, right, bottom, left
            if (!element.hasOwnProperty("isLazyloadData") || !element.isLazyloadData) {
                getlazyLoadInfo(this, element.userInfo, element.userId);
                this.$set(element, 'isLazyloadData', true);
                console.log("onWaypoint");
            }
        },
        getClassBind(element) {
            return {
                'low-balance': element.balance < 10,
                'blink-row-tr': (element.softwareFocus && element.softwareFocus.isHighlight),
                'partnerUser': element.userInfo && element.userInfo.partnerInfo && element.userInfo.partnerInfo !== null
            };
        }
    }
}